<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="orders"
      :item-class="itemBG"
      :loading="isLoadingOrders"
      :server-items-length="ordersMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('order-view')"
          small
          class="mr-2"
          @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
      </template>

      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("orders") }}</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getFilteredCheckoutA4(options)">
            <v-icon class="mr-2" color="white"> mdi-printer-outline </v-icon>
            {{ $t("print") }}
          </v-btn>
          <show-order v-if="$admin.can('order-view')"></show-order>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="3">
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="options.first_order"
              :label="$t('first_order')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="options.last_order"
              :label="$t('last_order')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              clearable
              v-model="options.order_status_id"
              :items="orderStatuses"
              item-text="name"
              item-value="id"
              :label="$t('status')"
              @focus="getOrderStatuses"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              clearable
              v-model="options.order_origin_id"
              :items="orderOrigins"
              item-text="name"
              item-value="id"
              :label="$t('order_origin')"
              @focus="getOrderOrigins"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="1">
            <v-btn
              fab
              small
              @click="filterOrders"
              color="primary"
              class="mx-0 my-3"
            >
              <v-icon color="white"> mdi-filter </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [helpers],

  components: {
    ShowOrder: () => import("./dialogs/ShowOrder.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingOrders: "orders/isLoadingOrders",
      orders: "orders/listOrders",
      ordersMeta: "orders/meta",
      orderStatuses: "orderStatuses/listStatuses",
      orderOrigins: "orderOrigins/getOrderOrigins",
      hubs: "hubs/activeHubs",
    }),
  },

  watch: {
    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          for: "orders",
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      is_loading_order: false,
      options: {},
      index: 0,
      is_loading_invoice: false,
      is_loading_dn: false,

      headers: [
        {
          text: "ID",
          align: "start",
          value: "tracking_number",
        },
        {
          text: "ID extern",
          align: "start",
          value: "tracking_number_ext",
        },

        {
          text: this.$t("status"),
          align: "center",
          value: "orderStatus.name",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("created_at"),
          value: "created_at",
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },

  methods: {
    showItem(item) {
      this.$store.dispatch("orders/openShowForm", item);
    },

    async filterOrders() {
      this.options["sortBy"][0] = "created_at";
      this.options["sortDesc"][0] = "false";
      await this.$store.dispatch("orders/list", this.options);
    },

    itemBG(item) {
      if (item?.order_origin?.name == "API_Express") return "red lighten-5";
      return "";
    },

    async getFilteredCheckoutA4(filter) {
      this.is_loading_dn = true;
      await this.$store
        .dispatch("orders/getFilteredCheckoutA4", filter)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "checkout.pdf");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_dn = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_dn = false;
        });
    },
  },
};
</script>
